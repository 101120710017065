import LayOut from "@components/layout";
import SEO from "@components/shared/seo";
import { colors } from "@util/constants";
import { Container, MenuButtonGatsbyLink } from "@util/standard";
import React from "react";
import styled from "styled-components";

const StyledLink = styled(MenuButtonGatsbyLink)`
  width: fit-content;
  padding-inline: 20px;
  align-self: start;
  margin: 0;
`;

const SignIn = () => {
  return (
    <>
      <SEO
        overwriteTitle={"Celo | Get Started"}
        overwriteDescription={
          "Log in to Celo and upgrade your plan or create a new account to start communicating securely with your team."
        }
      />
      <LayOut>
        <Container
          margin="100px auto 200px auto"
          display="flex"
          justifyContent="center"
          style={{ gap: "100px" }}
          isMobileColumn
        >
          <Container
            width="400px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            style={{
              padding: "30px",
              border: `1px solid ${colors.lightBlue}`,
              borderRadius: "10px",
            }}
          >
            <Container>
              <p style={{ fontSize: "24px", marginBottom: "10px" }}>
                Already have an account?
              </p>
              <p style={{ fontSize: "16px", color: colors.grey }}>
                Log in to Celo and upgrade your plan.
              </p>
            </Container>
            <StyledLink
              href={"https://workspace-admin.celohealth.com/"}
              target="_blank"
              color="tertiary"
            >
              Log in to Celo
            </StyledLink>
          </Container>
          <Container
            width="400px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            style={{
              padding: "30px",
              backgroundColor: colors.babyBlue,
              borderRadius: "10px",
            }}
          >
            <Container>
              <p style={{ fontSize: "24px", marginBottom: "10px" }}>
                New user?
              </p>
              <p style={{ fontSize: "16px", color: colors.grey }}>
                Create a new account to start communicating securely with your
                team.
              </p>
            </Container>
            <StyledLink
              href={"https://workspace-admin.celohealth.com/onboard"}
              target="_blank"
              color="secondary"
            >
              Create a Celo Account
            </StyledLink>
          </Container>
        </Container>
      </LayOut>
    </>
  );
};

export default SignIn;
